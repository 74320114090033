<template>
    <CRow class="justify-content-center">
        <CCol sm="12">
            <CCardBody>
                <CRow class="justify-content-center">
                    <CCol sm="12" lg="5">
                        <CRow> 
                            <CCol sm="12">     
                                <!-- @input="formMachine.MachineConditionId = $event.target.value" -->
                                <CSelect
                                    :label="$t('label.machineCondition')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value="formMachine.MachineConditionId"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.MachineConditionId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.MachineConditionId)"                
                                    :options="optionListCondition"
                                    @change="conditionChange($event.target.value)" 
                                    :is-valid="hasError($v.formMachine.MachineConditionId)"  
                                    @blur="$v.formMachine.MachineConditionId.$touch()"
                                    size="sm"
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="12" v-if="verifySuplier">
                                <CSelect
                                    :label="$t('label.machineSupplier')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value="formMachine.ClientTpId" 
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.ClientTpId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.ClientTpId)" 
                                    :options="optionListMachineSuplier"                      
                                    @change="formMachine.ClientTpId = $event.target.value" 
                                    :is-valid="hasError($v.formMachine.ClientTpId)"  
                                    @blur="$v.formMachine.ClientTpId.$touch()"
                                    size="sm"                   
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.machineName')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required onlyAlphanumeric text-right"
                                    v-uppercase          
                                    :placeholder="$t('label.machineName')"      
                                    v-model="$v.formMachine.MachineName.$model"
                                    @blur="$v.formMachine.MachineName.$touch()"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.MachineName.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.MachineName)"
                                    required 
                                    :is-valid="hasError($v.formMachine.MachineName)" 
                                    maxlength="250"
                                    size="sm"
                                >
                                </CInput>
                            </CCol>
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.machineType')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value="formMachine.TpMachineId"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.TpMachineId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.TpMachineId)"
                                    @blur="$v.formMachine.TpMachineId.$touch()"
                                    @change="formMachine.TpMachineId = $event.target.value"
                                    :options="optionListType"
                                    :is-valid="hasError($v.formMachine.TpMachineId)"
                                    size="sm"
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.brand')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value="formMachine.MarcaId"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.MarcaId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.MarcaId)"
                                    @blur="$v.formMachine.MarcaId.$touch()"
                                    @change="changeBranch($event.target.value)"
                                    :options="optionListBranch"
                                    :is-valid="hasError($v.formMachine.MarcaId)"
                                    size="sm"
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.model')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value.sync="formMachine.ModelId"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.ModelId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.ModelId)"
                                    @blur="$v.formMachine.ModelId.$touch()"
                                    v-model="formMachine.ModelId"
                                    :options="optionListModel"
                                    :is-valid="hasError($v.formMachine.ModelId)"
                                    size="sm"
                                >
                                </CSelect>
                            </CCol>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.serial')"
                                    :placeholder="$t('label.serial')"  
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="text-right"
                                    v-uppercase
                                    v-model="$v.formMachine.Serial.$model"
                                    @blur="$v.formMachine.Serial.$touch()"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.Serial.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.Serial)"
                                    :is-valid="hasError($v.formMachine.Serial)" 
                                    maxlength="50"
                                    size="sm"
                                >
                                </CInput>
                            </CCol>  
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.state')"
                                    :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                    add-label-classes="required text-right"
                                    :value="formMachine.ProcessStatusId"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.ProcessStatusId.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.ProcessStatusId)"
                                    @blur="$v.formMachine.ProcessStatusId.$touch()"
                                    @change="formMachine.ProcessStatusId = $event.target.value"
                                    :options="optionListEstado"
                                    :is-valid="hasError($v.formMachine.ProcessStatusId)"
                                    size="sm"
                                >
                                </CSelect>
                            </CCol>                                
                        </CRow>
                    </CCol>
                    <CCol sm="12" lg="6">
                        <CRow>
                            <CCol sm="12">
                                <CInput
                                    :label="$t('label.code')"
                                    :placeholder="$t('label.code')"
                                    :horizontal="{label: 'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                                    add-label-classes="required text-right"
                                    v-uppercase
                                    maxlength="10"
                                    v-model="$v.formMachine.Cod.$model"
                                    @blur="$v.formMachine.Cod.$touch()"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.Cod.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.Cod)"
                                    required 
                                    :is-valid="hasError($v.formMachine.Cod)" 
                                    size="sm"
                                >
                                </CInput>
                            </CCol> 
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.year')"
                                    :horizontal="{label: 'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                                    add-label-classes="text-right"
                                    :value="formMachine.Year"
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.Year.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.Year)"
                                    @blur="$v.formMachine.Year.$touch()"
                                    @change="formMachine.Year = $event.target.value"
                                    :options="optionListYear"
                                    :is-valid="hasError($v.formMachine.Year)"
                                    size="sm"
                                >
                                </CSelect>                    
                            </CCol>
                            <CCol sm="12">
                                <div class="form-group form-row">
                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{ $t('label.maxLoadCapacity') }}</label>
                                    <div class="input-group input-group-sm col-sm-12 col-lg-6">
                                        <money
                                            v-model.trim="$v.formMachine.MaxCapacity.$model"
                                            @blur="$v.formMachine.MaxCapacity.$touch()"
                                            v-bind="measureMaxCapacity"
                                            :class="computedMaxCapacity 
                                                ? 'form-control is-valid' 
                                                : (verifyMaxCapacity ? 'form-control is-invalid' : 'form-control') 
                                            "
                                        >
                                        </money>
                                        <div class="input-group-append">
                                            <span class="input-group-text">t</span>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                            <CCol sm="12">
                                <div class="form-group form-row">
                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{ $t('label.liftingSpeed') }}</label>
                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                            v-model.trim="$v.formMachine.SpeedCharge.$model"
                                            v-bind="measure"
                                            :class="computedSpeedCharge 
                                                ? 'form-control is-valid' 
                                                : (verifySpeedCharge ? 'form-control is-invalid' : 'form-control') 
                                            "
                                        >
                                        </money>
                                        <div class="input-group-append">
                                            <CSelect
                                                :value.sync="formMachine.UnitMeasureIdSC"
                                                @blur="$v.formMachine.UnitMeasureIdSC.$touch()"
                                                v-model="formMachine.UnitMeasureIdSC"
                                                :options="optionListUnityMesure"
                                                :addInputClasses=" {'is-invalid': $v.formMachine.UnitMeasureIdSC.$error }"
                                                class="m-0"
                                                :is-valid="hasError($v.formMachine.UnitMeasureIdSC)"
                                                size="sm"  
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </CCol>
                            <CCol sm="12">
                                <div class="form-group form-row">
                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{ $t('label.loweringSpeed') }}</label>
                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                            v-model.trim="$v.formMachine.SpeedDischarge.$model"
                                            v-bind="measure"
                                            :class="computedSpeedDischarge 
                                                ? 'form-control is-valid' 
                                                : (verifySpeedDischarge ? 'form-control is-invalid' : 'form-control') 
                                            "
                                        >
                                        </money>
                                        <div class="input-group-append">
                                            <CSelect
                                                :value.sync="formMachine.UnitMeasureIdSD"
                                                @blur="$v.formMachine.UnitMeasureIdSD.$touch()"
                                                v-model="formMachine.UnitMeasureIdSD"
                                                :options="optionListUnityMesure"
                                                :addInputClasses=" {'is-invalid': $v.formMachine.UnitMeasureIdSD.$error }"
                                                class="m-0"
                                                :is-valid="hasError($v.formMachine.UnitMeasureIdSD)"
                                                size="sm"   
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </CCol>  
                            <CCol sm="12">
                                <div class="form-group form-row">
                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{ $t('label.maximumRadio') }}</label>
                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                        <money
                                            v-model.trim="$v.formMachine.MaxRadio.$model"
                                            @blur="$v.formMachine.MaxRadio.$touch()"
                                            v-bind="measure"
                                            :class="computedMaxRadio 
                                                ? 'form-control is-valid' 
                                                : (verifyMaxRadio ? 'form-control is-invalid' : 'form-control') 
                                            "
                                        >
                                        </money>
                                        <div class="input-group-append">
                                            <span class="input-group-text">M</span>
                                        </div>
                                    </div>
                                </div>
                            </CCol>                          
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.loadDischargeOperations')"
                                    :horizontal="{label: 'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                                    add-label-classes="text-right"
                                    v-model="formMachine.FgOperation"
                                    :value.sync="formMachine.FgOperation"
                                    :options="selectOptionsDescarga"
                                    :is-valid="hasError($v.formMachine.FgOperation)" 
                                    :addInputClasses="{ 'is-invalid': $v.formMachine.FgOperation.$error }"
                                    :invalid-feedback="errorMessage($v.formMachine.FgOperation)"
                                    @blur="$v.formMachine.FgOperation.$touch()"
                                    size="sm"
                                />
                            </CCol>
                            <CCol sm="12">
                                <CSelect
                                    :label="$t('label.status')"
                                    :horizontal="{label: 'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                                    add-label-classes="required text-right"
                                    :is-valid="formMachine.FgActMachine"
                                    v-if="isEdit"
                                    v-model="formMachine.FgActMachine"
                                    :value.sync="formMachine.FgActMachine"
                                    :options="selectOptions"
                                    size="sm"
                                />
                            </CCol>                                            
                        </CRow>           
                    </CCol>
                </CRow>
            </CCardBody>
            <CCardFooter>
                <CCol sm="12" class="d-flex justify-content-end">
                    <CButton
                        color="add"
                        class="m-2 mt-2"
                        @click="registerData"
                        :disabled="apiStateFormLoading"
                    >
                        <div v-if="apiStateFormLoading">
                            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                        </div>

                        <div v-if="!apiStateFormLoading">
                            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                        </div>
                    </CButton>
                    <CButton
                        shape="square"
                        color="wipe"
                        @click="cerrarCollapse"
                        class="m-2 mt-2"
                        :disabled="apiStateFormLoading"
                    >
                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                    </CButton>
                </CCol>
            </CCardFooter>
        </CCol>
    </CRow>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import { mapState } from 'vuex';
import ENUM from '@/_store/enum';
import { FormMachine } from '@/_validations/maquina/MaquinaValidation';
import NumberInput from '@/components/numberinput';
import { errorMessage } from '@/_helpers/funciones';
import mixinServicio from '@/_mixins/servicio';
import { VMoney, Money } from "v-money";

function data() {
  return {
    isEdit: false,
    isSubmit: false,
    verifySuplier: false,
    condicion: '',
    originalAct: '', 
    MarcaId:'', 
    formMachine:{
        MachineId:0,
        TpMachineId:'',
        MachineConditionId:'',
        MarcaId:'',
        ModelId:'',
        MachineName:'',
        ProcessStatusId:'',
        ClientTpId:'',
        Year:0,
        Cod:'',
        Serial:'',
        MaxCapacity:0,
        MaxRadio:0,
        UnitMeasureIdSC:'',
        UnitMeasureIdSD:'',
        SpeedCharge:0,
        SpeedDischarge:0,
        FgActMachine:true,
        FgOperation:true
    },
    selectOptions: [
        { 
            value: true, 
            label: this.$t('label.ACTIVO')
        },
        { 
            value: false, 
            label: this.$t('label.INACTIVO')
        }
    ],
    selectOptionsDescarga: [
        { 
            value: true, 
            label: this.$t('label.yes')
        },
        { 
            value: false, 
            label: 'NO'
        }
    ],
    measure: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
        maxlength: 6
    },
    measureMaxCapacity: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
        maxlength: 9
    },
    verifySpeedCharge: false,
    verifySpeedDischarge: false,
    verifyMaxCapacity: false,
    verifyMaxRadio: false,
  };
}

//methods
  function getData(){
        const id = this.idState;
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'Machine-by-id',{ MachineId: id }, '' ).then(response => {
            try {
                if(response.status === 200){
                    const Information = response.data.data;
                    if(Information.length !== 0){
                      this.isEdit = true;    
                        this.formMachine.MachineId=Information[0].MachineId;
                        this.formMachine.TpMachineId=Information[0].TpMachineId;
                        this.formMachine.MachineConditionId=Information[0].MachineConditionId;
                        this.formMachine.MarcaId = Information[0].BrandId;
                        this.formMachine.ModelId= Information[0].ModelId;
                        this.formMachine.MachineName= Information[0].MachineName;
                        this.formMachine.ProcessStatusId= Information[0].ProcessStatusId;
                        this.formMachine.ClientTpId=Information[0].ClientTpId ? Information[0].ClientTpId : '';
                        this.formMachine.Year=Information[0].Year;
                        this.formMachine.Cod=Information[0].Cod;
                        this.formMachine.Serial=Information[0].Serial;
                        this.formMachine.MaxCapacity=Information[0].MaxCapacity ? parseFloat(Information[0].MaxCapacity).toFixed(2) : 0;
                        this.formMachine.MaxRadio=Information[0].MaxRadio;
                        this.formMachine.UnitMeasureIdSC=Information[0].UnitMeasureIdSC;
                        this.formMachine.UnitMeasureIdSD=Information[0].UnitMeasureIdSD;
                        this.formMachine.SpeedCharge=Information[0].SpeedCharge ? parseFloat(Information[0].SpeedCharge).toFixed(2) : 0;
                        this.formMachine.SpeedDischarge=Information[0].SpeedDischarge ? parseFloat(Information[0].SpeedDischarge).toFixed(2) : 0;
                        this.formMachine.FgActMachine=Information[0].FgActMachine;
                        this.formMachine.FgOperation=Information[0].FgOperation ? true : false;
                        this.originalAct = Information[0].FgActMachine;

                        this.verifySpeedCharge = true;
                        this.verifySpeedDischarge = true;
                        this.verifyMaxCapacity = true;
                        this.verifyMaxRadio = true;

                        this.$store.dispatch('maquina/getTpMachinelist');
                        this.$store.dispatch('maquina/getConditionMachinelist');
                        if (this.formMachine.MachineConditionId != ''){
                          this.conditionChange(this.formMachine.MachineConditionId);  
                        }
                        this.$store.state.maquina.NewMachineName= Information[0].MachineName;
                        this.$store.state.maquina.NewMachineId= Information[0].MachineId
                        this.$v.formMachine.$touch();
                    }
                    this.$store.state.maquina.apiStateForm = ENUM.INIT;
                }else{
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                }
            } catch (error) {
                this.$store.commit('maquina/messageMutation', error);
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.commit('maquina/messageMutation', err);
            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
        });

      
  } 
  function conditionChange(value){
    this.formMachine.MachineConditionId = value;

    const id = value; 
    this.$http.ejecutar('GET', 'MachineCondition-by-id',{ MachineConditionId: id }, '' ).then(response => {
      try {
        if(response.status === 200){
          const Information = response.data.data;
          if(Information.length !== 0){
            if(Information[0].FgSuplier === true)
              {this.verifySuplier=true;}
            else
              {this.verifySuplier=false}
          }
        }else{
          this.$store.state.maquina.apiStateForm = ENUM.ERROR;
        }
      } catch (error) {
        this.$store.commit('maquina/messageMutation', error);
        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
      }
    }).catch(err => {
      this.$store.commit('maquina/messageMutation', err);
      this.$store.state.maquina.apiStateForm = ENUM.ERROR;
    });
  }
  function checkNumeros($event) {    // verifica que sean solo numeros
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 188) {
      $event.preventDefault();
    }
  }
  function changeBranch(value){
    this.formMachine.MarcaId = value;
    if(value !== ''){
      this.getDataModel();
    }   
  }
  function getDataModel(){
      this.$store.state.maquina.apiStateForm = ENUM.LOADING;
      this.$http.ejecutar('GET', 'Model-list', { BrandId:this.formMachine.MarcaId,Filter: 'ACTIVO' }, '').then(response => {
          if(response.status === 200){
              this.$store.state.maquina.myDataModel = response.data.data;
              this.$store.state.maquina.apiStateForm = ENUM.INIT;
          }else{
              this.$store.state.maquina.apiStateForm = ENUM.ERROR;
          }
      }).catch(err => {
          this.$store.state.maquina.apiState = ENUM.ERROR;
          this.$store.commit('maquina/messageMutation', err);
      });
  }
  async function registerData(){
    try {
      this.$store.state.maquina.apiStateForm = ENUM.LOADING;
      this.isSubmit = true;
      this.verifySpeedCharge = true;
      this.verifySpeedDischarge = true;
      this.verifyMaxCapacity = true;
      this.verifyMaxRadio = true;
      this.$v.formMachine.$touch();

      if (this.$v.formMachine.$error) {
        this.$store.state.maquina.apiStateForm = ENUM.INIT;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      const MachineJson = { 
        CompanyBranchId:this.branch.CompanyBranchId,
        TpMachineId:this.formMachine.TpMachineId,
        MachineConditionId:this.formMachine.MachineConditionId,
        ProcessStatusId:this.formMachine.ProcessStatusId,
        ClientTpId:this.formMachine.ClientTpId,
        ModelId:this.formMachine.ModelId,
        MachineName:this.formMachine.MachineName,
        Year:this.formMachine.Year,
        Cod:this.formMachine.Cod,
        Serial:this.formMachine.Serial,
        MaxCapacity:this.formMachine.MaxCapacity,
        MaxRadio:this.formMachine.MaxRadio,
        SpeedCharge:this.formMachine.SpeedCharge,
        UnitMeasureIdSC:this.formMachine.UnitMeasureIdSC,
        SpeedDischarge:this.formMachine.SpeedDischarge,
        UnitMeasureIdSD:this.formMachine.UnitMeasureIdSD,
        FgOperation:this.formMachine.FgOperation
      };

      if(this.isEdit){
        MachineJson.MachineId = this.formMachine.MachineId;
        MachineJson.Status = this.formMachine.FgActMachine ? 1 : 0;
      };
      let method = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'Machine-update':'Machine-insert';

      await this.$http.ejecutar (method, ruta, MachineJson, { root: 'MachineJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
           // this.closeModal();
            this.$store.state.maquina.apiStateForm = ENUM.INIT;
            this.$store.state.maquina.idState= response.data.data[0].MachineId;
            this.$store.state.maquina.NewMachineId= response.data.data[0].MachineId;
            this.$store.state.maquina.NewMachineName= response.data.data[0].MachineName;
            this.isSubmit = false;
            this.refreshComponent();
            this.$emit('child-refresh');
            this.$notify({
              group: 'container',
              title: '¡Exito!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.$store.state.maquina.apiStateForm = ENUM.INIT;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        });
      this.$store.state.maquina.apiStateForm = ENUM.INIT;
    } catch (e) {
      this.$store.state.maquina.apiStateForm = ENUM.INIT;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  }
  function cerrarCollapse(){
    this.refreshComponent();
    this.$emit('closeCollapse', true);
  }
  function refreshComponent() {
    this.formMachine.MachineId=0;
    this.formMachine.TpMachineId='';
    this.formMachine.MachineConditionId='';
    this.formMachine.MarcaId = '';
    this.formMachine.ModelId='';
    this.formMachine.MachineName='';
    this.formMachine.ProcessStatusId='';
    this.formMachine.ClientTpId='';
    this.formMachine.Year=0;
    this.formMachine.Cod='';
    this.formMachine.Serial='';
    this.formMachine.MaxCapacity=0;
    this.formMachine.MaxRadio=0;
    this.formMachine.UnitMeasureIdSC='';
    this.formMachine.UnitMeasureIdSD='';
    this.formMachine.SpeedCharge=0;
    this.formMachine.SpeedDischarge=0;
    this.formMachine.FgActMachine=true;
    this.originalAct = '';
    this.verifySuplier= false;
    this.isEdit= false;
    
    this.verifySpeedCharge = false;
    this.verifySpeedDischarge = false;
    this.verifyMaxCapacity = false;
    this.verifyMaxRadio =  false;
    this.$nextTick(() => { this.$v.$reset() })
  }
//watch
  function idState(newQuestion){
    if(this.idState === 0){
        this.refreshComponent();
    }else{ 
      this.getData();
    }
  }
  async function CollapseMachine(newQuestion){
    if(newQuestion === false){
      this.refreshComponent();
    }else{
      if(this.idState != 0){
        this.getData();
      }
    }
  }
//computed
  function apiStateFormLoading() {
    let carga = false;
    if(this.apiStateForm === ENUM.LOADING){
      carga = !carga;
    }
    return carga;
  }
  function optionListYear(){
      const chart = [];
      var fecha = new Date();
      var ano = fecha.getFullYear();
      let n = 1950;
      while (n < ano) {
          n ++;
          chart.push(n)
      }
      return chart;
  }
  function optionListUnityMesure(){
      if(this.myDataUnityMesure.length === 0){
          return [{
              value: '', 
              label: "*",
          }];
      }else{
        if(this.formMachine.UnitMeasureIdSC == '')
            this.formMachine.UnitMeasureIdSC=this.myDataUnityMesure[0].UnitMeasureId;
        if(this.formMachine.UnitMeasureIdSD == '')
            this.formMachine.UnitMeasureIdSD= this.myDataUnityMesure[0].UnitMeasureId;

          var chart = []
          this.myDataUnityMesure.map(function(e){
              if(e.FgActUnitMeasure){
                  chart.push({
                      value: e.UnitMeasureId, 
                      label: e.UnitMeasureAbbrev,
                  })
              }
          })
          return chart;
      }
  }
  function optionListEstado(){
      if(this.myDataStatus.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
          }];
      }else{
          var chart = [{
              value: '', 
              label: this.$t('label.select'),
          }]
          this.myDataStatus.map(function(e){
              chart.push({
                  value: e.ProcessStatusId, 
                  label: e.ValueDs,
              })
          })
          return chart;
      }
  }
  function optionListMachineSuplier(){
      if(this.myDataMachineSuplier.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
          }];
      }else{
          var chart = [{
              value: '', 
              label: this.$t('label.select'),
          }]
          this.myDataMachineSuplier.map(function(e){
              if(e.FgActClientTp){
                  chart.push({
                      value: e.ClientTpId, 
                      label: e.ClientName,
                  })
              }
          })
          return chart;
      }
  }
  function optionListBranch(){
      if(this.myDataBrand.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
          }];
      }else{
          var chart = [{
              value: '', 
              label: this.$t('label.select'),
          }]
          this.myDataBrand.map(function(e){
              if(e.FgActBrand){
                  chart.push({
                      value: e.BrandId, 
                      label: e.BrandName,
                  })
              }
          })
          return chart;
      }
  }
  function optionListModel(){
    if(this.myDataModel.length === 0){
        return [{
            value: '', 
            label: this.$t('label.select'),
        }];
    }else{
        var chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]
        this.myDataModel.map(function(e){
            if(e.FgActModel){
                chart.push({
                    value: e.ModelId, 
                    label: e.ModelName,
                })
            }
        })
        return chart;
    }
  }
  function optionListCondition(){
      if(this.myDataMachineCondition.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
          }];
      }else{
          var chart = [{
              value: '', 
              label: this.$t('label.select'),
          }]
          this.myDataMachineCondition.map(function(e){
              if(e.FgActMachineCondition){
                  chart.push({
                      value: e.MachineConditionId, 
                      label: e.MachineConditionName,
                  })
              }
          })
          return chart;
      }
  }
  function optionListType(){
    let _lang = this.$i18n.locale;
      if(this.myDataMachineTp.length === 0){
          return [{
              value: '', 
              label: this.$t('label.select'),
          }];
      }else{
          var chart = [{
              value: '', 
              label: this.$t('label.select'),
          }]
          this.myDataMachineTp.map(function(e){
              if(e.FgActTpMachine){
                  chart.push({
                      value: e.TpMachineId, 
                      label: _lang=='en' ? e.TpMachineNameEn: e.TpMachineNameEs,
                  })
              }
          })
          return chart;
      }
  }
  function computedMaxCapacity() { 
    if (this.formMachine.MaxCapacity < 0) {
        return false;
    } else if (this.formMachine.MaxCapacity == 0 && !this.verifyMaxCapacity) {
        return false;
    } else if (this.formMachine.MaxCapacity > 100000) {
        this.verifyMaxCapacity = true;
        return false;
    } else {
        return true;
    }
  }
  function computedSpeedCharge() { 
    if (this.formMachine.SpeedCharge < 0) {
        return false;
    } else if (this.formMachine.SpeedCharge == 0 && !this.verifySpeedCharge) {
        return false;
    } else if (this.formMachine.SpeedCharge > 1000) {
        this.verifySpeedCharge = true;
        return false;
    } else {
        return true;
    }
  }
  function computedSpeedDischarge () { 
    if (this.formMachine.SpeedDischarge  < 0) {
        return false;
    } else if (this.formMachine.SpeedDischarge  == 0 && !this.verifySpeedDischarge ) {
        return false;
    } else if (this.formMachine.SpeedDischarge  > 1000) {
        this.verifySpeedDischarge  = true;
        return false;
    } else {
        return true;
    }
  }
  function computedMaxRadio() { 
    if (this.formMachine.MaxRadio < 0) {
        return false;
    } else if (this.formMachine.MaxRadio == 0 && !this.verifyMaxRadio) {
        return false;
    } else if (this.formMachine.MaxRadio > 100000) {
        this.verifyMaxRadio = true;
        return false;
    } else {
        return true;
    }
  }
export default {
  name: 'add-machine',
  data,
  props: {
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,
  validations(){ return FormMachine(this.verifySuplier) },
  components: {
    NumberInput,
    Money,
  },
  watch: {
    idState,
    CollapseMachine,
  },
  methods: {
    refreshComponent,
    getData,
    getDataModel,
    changeBranch,
    registerData,
    cerrarCollapse,
    checkNumeros,
    errorMessage,
    conditionChange,
  },
  computed: {
    optionListYear,
    optionListCondition,
    optionListUnityMesure,
    optionListType,
    optionListBranch,
    optionListModel,
    optionListMachineSuplier,
    optionListEstado,
    apiStateFormLoading,
    computedSpeedCharge,
    computedSpeedDischarge,
    computedMaxCapacity,
    computedMaxRadio,
    ...mapState({
      idState: state => state.maquina.idState,
      myDataMachineTp: state => state.maquina.myDataMachineTp,
      myDataMachineCondition: state => state.maquina.myDataMachineCondition,
      myDataBrand: state => state.maquina.myDataBrand,
      myDataUnityMesure: state => state.maquina.myDataUnityMesure,
      myDataMachineSuplier: state => state.maquina.myDataMachineSuplier,
      myDataStatus: state => state.maquina.myDataStatus,
      myDataModel: state => state.maquina.myDataModel,
      apiStateForm: state => state.maquina.apiStateForm,
      CollapseMachine: state=> state.maquina.CollapseMachine,
      formReset: state => state.maquina.formReset,
      branch: state => state.auth.branch,
      NewMachineId: state => state.maquina.NewMachineId,
      NewMachineName: state => state.maquina.NewMachineName,
    })
  },
};
</script>
<style lang="scss">
</style>
